//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-778:_9762,_5520,_9916,_5852,_9264,_7324,_808,_3120;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-778')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-778', "_9762,_5520,_9916,_5852,_9264,_7324,_808,_3120");
        }
      }catch (ex) {
        console.error(ex);
      }