
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'cm_product-item grid-item__link' }, _createElement('a', {
                    'href': this.url,
                    'className': 'cm_product-item__image-container'
                }, _createElement('img', {
                    'className': 'cm_product-item__image' + (this.image2 ? ' cm_product-item__image_primary' : ''),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'cm_product-item__image cm_product-item__image_secondary',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '355'
                }) : null, _createElement('div', { 'className': 'card__badge' }, this.out_of_stock ? _createElement('span', {
                    'id': 'NoMediaStandardBadge--' + this.id,
                    'className': 'badge color-inverse',
                    'key': '617'
                }, '\n        Ausverkauft\n      ') : null, this.is_replaced ? _createElement('span', {
                    'id': 'NoMediaStandardBadge--' + this.id,
                    'className': 'badge color-inverse',
                    'key': '755'
                }, '\n        Artikel ersetzt\n      ') : null)), _createElement('div', { 'className': 'cm_product-item__details' }, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'cm_product-item__vendor'
                }, { dangerouslySetInnerHTML: { __html: this.original_vendor ? this.original_vendor : this.vendor } })), _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'cm_product-item__title'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'cm_product-item__sku-container' }, this.sku ? _createElement('div', {
                    'className': 'cm_product-item__sku',
                    'key': '1295'
                }, _createElement('span', mergeProps({ 'className': 'sku-value' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null), _createElement('div', { 'className': 'cm_product-item__status-container' }, this.free_shipping ? _createElement('div', {
                    'className': 'cm_product-item__status cm_product-item__status_free-shipping',
                    'key': '1521'
                }, _createElement('img', {
                    'className': 'cm_product-item__status_free-shipping-icon',
                    'alt': 'Free Shipping',
                    'src': 'https://cdn.shopify.com/s/files/1/0576/3609/3986/files/Free-06.png'
                })) : null)), _createElement('div', { 'className': 'cm_product-item__bottom-section' }, _createElement('div', { 'className': 'cm_product-item__price-container' }, this.on_sale ? _createElement('div', {
                    'className': 'cm_product-item__price cm_product-item__price_compare',
                    'key': '1921'
                }, _createElement('span', {}, this.formatPrice(this.compare_at_price))) : null, _createElement('div', { 'className': 'cm_product-item__price ' + (this.on_sale ? 'cm_product-item__price-sale' : 'cm_product-item__price-regular') }, this.price_varies ? _createElement('span', { 'key': '2239' }, 'Ab: ') : null, _createElement('span', { 'className': 'js-price' }, this.formatPrice(this.price)))), _createElement('div', { 'className': 'cm_product-item__buttons' }, !this.out_of_stock && this.price ? [_createElement('form', {
                        'action': '/cart/add',
                        'method': 'post',
                        'className': 'cm_product-item__buttons-form',
                        'id': this.id,
                        'encType': 'multipart/form-data',
                        'key': '24251'
                    }, this.variant_ids.length > 1 ? [_createElement('a', {
                            'className': 'cm_button button button--secondary',
                            'href': this.url,
                            'aria-label': 'button',
                            'key': '26281'
                        }, '\n              Optionen auswählen\n            ')] : null, this.variant_ids.length === 1 ? [
                        _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids,
                            'key': '28661'
                        }),
                        _createElement('input', {
                            'type': 'hidden',
                            'name': 'quantity',
                            'value': '1',
                            'key': '28663'
                        }),
                        !this.not_available ? _createElement('button', {
                            'className': 'cm_button button button--primary',
                            'type': 'submit',
                            'data-form-id': this.id,
                            'disabled': this.is_replaced,
                            'key': '28665'
                        }, '\n              ', this.is_replaced ? 'Artikel ersetzt' : 'zum Warenkorb hinzufügen', '\n            ') : null,
                        this.not_available ? _createElement('button', {
                            'className': 'cm_button button button--secondary',
                            'disabled': 'true',
                            'key': '28667'
                        }, '\n              \'Nicht verfügbar\'\n            ') : null
                    ] : null)] : null)), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '35960'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '3995'
                }) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'Das könnte dir auch gefallen')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []